.gridContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  font-size: 1.3rem;
  
}

.flexContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.singleLine{

}

.textLine{
    display: flex;
    font-weight: 600;
    font-size: 1rem;
    margin-top: 1rem;
}

.textLineTwo{
    display: flex;
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 1rem;
}