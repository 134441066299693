.parent {
  min-height: 2rem;
  display: grid;
  grid-template-columns: 25% 75%;
  padding-left: 0.5rem;
  border-bottom: 1px solid #5e35b1;
}

.fieldName {
  flex: 1;
  min-width: 2.5rem;
  min-height: 2rem;
  height: 100%;
  color: #5e35b1;
  font-weight: 600;
}

.valueName {
  display: flex;
  font-weight: 600;
  justify-content: flex-start;
  text-transform: uppercase;
}

.rightFields {
  display: flex;
  flex: 3;
  align-items: center;
  border-radius: 0.5rem;
  min-height: 100%;
  font-weight: 600;
  justify-content: space-between;
  text-align: center;
  text-transform: uppercase;
}

.valueOption {
  text-align: center;
  border-radius: 0.5rem;
  min-width: 2rem;
  max-width: 100%;
  font-weight: 600;
}

.rightAlignment {
  display: flex;
  justify-content: space-between;
  min-width: fit-content;
  width: 5rem;
  max-width: 5rem;
  border: none;
}

.iconSize {
  font-size: medium;
}

@media (min-width: 700px) {
  .rightFields {
    
    max-width: 28.5rem;
  }
}

@media (min-width: 1025px) {
  /* .parent {
    flex-direction: row;
    justify-content: space-between;
    column-gap: 2.5rem;
  }
  /* 
  /* .rightFields {
    flex: 4;
    max-width: 26rem;
  } */
  /* .fieldName {
    flex: 1;
  }  */
}
