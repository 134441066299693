.parent {
  border-radius: 0.5rem;
  min-height: 2.5rem;
  max-height: 3.5rem;
  display: grid;
  grid-template-columns: 25% 75%;
}

.fieldName {
  display: flex;
  flex: 1;
  align-items: center;
  min-height: 2rem;
  height: 100%;
  color: #5e35b1;
  font-weight: 600;
  border-radius: 0.4rem;
  text-transform: capitalize;
  text-align: center;
}

.valueName {
  display: flex;
  flex: 2;
  align-items: center;
  border-radius: 0.5rem;
  min-height: 100%;
  font-weight: 600;
  justify-content: flex-start;
  text-align: center;
  text-transform: uppercase;
}

button {
  background: transparent;
  border: none;
  font-weight: 600;
  cursor: pointer;
}

.iconSize {
  font-size: medium;
}

@media (min-width: 900px) {
  .parent {
    flex-direction: row;
    max-height: 2.5rem;
  }
}
