.wrapper {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 0.7rem 0.7rem;
}

.left {
  display: flex;
  flex: 1;
  flex-direction: column;
  row-gap: 0.5rem;
  border-radius: 0.5rem;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

.right {
  flex: 1;
  flex-direction: column;
  border-radius: 0.5rem;
  row-gap: 0.5rem;
  display: flex;
  flex-wrap: wrap;
}

.gridContainerTop {
  display: flex;
  flex-direction: column;
  border: 1px solid #5e35b1;
  border-radius: 0.5rem;
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
}

.border {
  border: 1px solid #5e35b1;
  border-radius: 0.5rem;
  padding: 0.5rem;
  /* overflow-x: scroll; */
  word-break: break-all;
}

.nameLine {
  display: grid;
  grid-template-columns: 25% 75%;
  font-weight: 600;
}

.name {
  display: flex;
  flex: 1;
  align-items: center;
  min-height: 2rem;
  height: 100%;
  min-width: none;
  max-width: 7.8rem;
  color: #5e35b1;
  font-weight: 600;
  border-radius: 0.4rem;
  text-transform: capitalize;
  text-align: center;
}

.valueName,
.value {
  display: flex;
  flex: 1;
  align-items: center;
  border-radius: 0.5rem;
  min-height: 100%;
  font-weight: 600;
  padding: 0;
  justify-content: flex-start;
  text-align: center;
}

.flexContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 0.7rem;
  padding-left: 0.5rem;
  border: 1px solid #5e35b1;
  border-radius: 0.5rem;
}

.borderUp {
  border-top: 1px solid #5e35b1;
  display: flex;
  padding: 0.5rem 0;
  display: grid;
  grid-template-columns: 25% 75%;
  font-size: larger;
  align-items: center;
}

.highlights {
  display: flex;
  align-items: center;
  min-height: 2rem;
  font-weight: 600;
  text-transform: capitalize;
}

.values {
  display: flex; 
  justify-content: flex-start;
  align-items: center;
  color: #5e35b1;
  font-size: larger;
  font-weight: 600;
}

.filedName {
  display: flex; 
  align-items: center;
  background-color: #5e35b1;
  padding: 0.5rem;
  border-radius: 0.5rem;
  color: white;
  font-weight: 600;
  height: max-content;
  width: max-content;
}

.profit {
  display: grid;
  grid-template-columns: 25% 75%;
  align-items: center;
}

@media (min-width: 1025px) {
  .wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  
  .gridContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .flexContainer {
    width: 80%;
  }

  .gridContainerTop {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .border {
    min-height: 8rem;
  }

  .nameLine{
    display: flex;
  }
}
