.headings{
    display: flex;
    gap: 20px;
    background-color: #176B87;
    padding: 10px;
    justify-content: space-between;

}

.insideHeading{
    color: white;
    display: flex;
    gap:5px;
    font-family: souge;
    font-weight: 600;
}

.custDetailHeading{
    display: flex;
    justify-content: center;
    font-family: souge;
    font-weight: bold;
    border: 1px solid gray;
    margin-top: 20px;
    padding: 5px;
    background-color: #64CCC5;
    color: white;
    padding-left: 10px;
}

.accordBody, .custDetails, .custDetailsRemark{
    display: flex;
    justify-content: space-between;
    margin: 10px;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
   
}

.custDetailsBtm{
    display: flex;
    font-family: comfortaa;
    font-size: 13px;
    gap:5px;
    margin: 0;
    align-items: center;
    width: 300px;

}

.title{
    margin: 0;
    opacity: 0.6;
}

.val{
    margin: 0;
}

.accordTitle{
    color: white;
    margin: 0;
    font-family: souge;
    font-weight: 600;
}

.custDetailsRemark{
    border-bottom: 1px solid rgb(169, 169, 169);
    width: 100%;
    margin: 0;
}



.subAccordance , .customerSubDetails {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    margin-top: 15px;
  }

  .remarksData{
    padding-top: 15px;
    padding-bottom: 15px;
}


.edit{
    display: flex;
    font-size: 40px;
    margin-top: -16px;
    margin-left: 15px;
    gap: 20px;
}

.iconHtl{
    background: white;
    border-radius: 50%;
    padding: 5px;
    color: #176B87;
}

.iconHtl:hover{
    transform: scale(1.2);
}


.textare{
   width: 90%; 
   padding: 10px;
   margin: 15px;
}


.custDetailsBt{
    display: flex;
    align-items: center;
}